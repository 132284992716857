import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp,faXTwitter, faYoutube,faInstagram, faFacebookF, faLinkedinIn ,} from '@fortawesome/free-brands-svg-icons';

import facebook from "../asserts/footer/facebook.png";
import linkedin from "../asserts/footer/linkedin.png";
import twitter from "../asserts/footer/twitter.png";
import instagram from "../asserts/footer/instagram.png";
// import WhatsApp from "../asserts/footer/whatsapp.png";
import YouTube from "../asserts/footer/youtube.png";
import BackToTopButton from "../BackToTopButton";
import logo from "../asserts/footer/logo.png";
import "../footer/Footer.css"; // Import your CSS file

const Footer = () => {
  return (
    <div>
      <footer className="">
        <div className="container f1">
        <div className=" footer1">
        <div className="">
          <div className="row " style={{flexdirection:'row'}}>
            <div className="col lii">
              <img src={logo} alt="logo" className="footlogo" width="80" />
              <p>
                ProGlobalSolutions offers web development and digital marketing
                & Videos services to help businesses improve their online
                presence and achieve success.
              </p>
            </div>
            

            <div className="col">
              <h3>Services</h3>
              <div className="links">
                <ul class="links-list list-unstyled">
                  <li>
                    <Link to="/View">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/View">App Development</Link>
                  </li>
                  <li>
                    <Link to="/View">Digital Marketing</Link>
                  </li>
                  <li>
                    <Link to="/View"> Video Advertisement</Link>
                  </li>
                  <li>
                    <Link to="/View">ECommerace Solutions</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col lc mm">
              <h3 className="Links1">Links</h3>
              <div className="links aa">
                <Link to="/" className="links-a">
                  We do
                </Link>
                <Link to="/View" className="links-a">
                  Service
                </Link>

                <Link to="/Strategy" className="links-a">
                  Strategy
                </Link>
                
                <Link to="/Clients" className="links-a">
                  Clients
                </Link>
                <Link to="/Firstpage" className="links-a">
                  Works
                </Link>
                <Link to="/Secondpage" className="links-a">
                Contact Us
                </Link>
              </div>
            </div>

            <div className="col cl mmm  ms">
              <h3 className="Links3 mn">Contact</h3>
              <div className="contact-details det">
                <p>
                F3,1/113, Jmj Complex Proglobal Solution TNAU Nagar Otthakadai <br /> Madurai - 625016. <br />
                Tamil Nadu 625107.
                
                  <br />
                  +91 93606 20595 <br /> +91 95006 69444
                </p>
                <p></p>
              </div>
              <div className="contact-details"></div>
            </div>
            <div className="col bbb bv ">
              <h3 className="Links2 us ">Follow Us</h3>
              <div className="social sao">
                <div className="">
                  <div className=" icon-col jik">
                    <a
                      href="https://wa.me/9360620595"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                     <FontAwesomeIcon icon={faWhatsapp } style={{ color: '#ffffff' }} size="3x" />
                    </a>
                    <a
                      href="https://twitter.com/pgs_in"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faXTwitter}  style={{ color: '#ffffff' }} size="3x" />
                    </a>
                    <a
                      href="https://www.instagram.com/proglobalsolutions.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} style={{ color: '#ffffff' }} size="3x" />
                    </a>
                  </div>
                  <div className="col icon-col ml-3 kij kk">
                    <a
                      href="https://www.linkedin.com/company/pgs-in"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                     <FontAwesomeIcon icon={faLinkedinIn} style={{ color: '#ffffff' }} size="3x" />
                    </a>
                    <a
                      href="https://www.facebook.com/proglobalsolutions.in/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                       <FontAwesomeIcon icon={faFacebookF} style={{ color: '#ffffff' }} size="3x" />
                    </a>
                    <a
                      href="https://www.youtube.com/@ProGlobalSolutions"
                      target="_blank"
                      rel="noopener noreferrer"
                     
                    >
                      <FontAwesomeIcon icon={faYoutube}   style={{ color: '#ffffff',backgroundColo:'red' }} size="3x" className="yt"/>
                    </a>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </div>
        </div>
        </div>
      </footer>
      
      <div className="copyrights">
        <p className="Copy ">
          Copyright © 2024 Pro Global Solutions | All Rights Reserved
        </p>
      </div>
      <BackToTopButton />
    </div>
    




  );
};

export default Footer;