

import React, { useEffect,useState } from 'react';
import Carousell from '../carousel/Carousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import workpage from "../asserts/workpage/Workpagebg.jpg"
import Newappdesign from '../newappdesign/Newappdesign';
const Firstpage = () => {
  const [playing, setPlaying] = useState(true);
  const VIDEO_PATH =
  "https://www.youtube.com/embed/28Rto9JjTCA?si=VC09FW8uiAAJU6Wp";
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  
  useEffect(() => {
    const slidersecond = document.querySelector(".slidersecond");

    function activate(e) {
      const items = document.querySelectorAll(".itemsecond");
      e.target.matches(".next") && slidersecond.append(items[0]);
      e.target.matches(".prev") && slidersecond.prepend(items[items.length - 1]);
    }

    document.addEventListener("click", activate, false);

    return () => {
      document.removeEventListener("click", activate, false);
    };
  }, []);
 

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
   

  


  return (
    
    <>
    
    <section
          className="workbg"
          style={{
            backgroundImage:`url(${workpage})`,
          }}
        >
          <div className="workHead">
            <div className="container">
              <h1 className="workHead1 head1 " style={{ textAlign: "center" ,marginTop:"",fontWeight:"800"}}>
              <span className="our" >OUR   </span>   <span className="work-head-sub ser "  style={{ fontWeight:"800"}}>  WORKS</span>
              </h1>
            </div>
          </div>
        </section>

          <section className="page">
</section>
         

{<Carousell/>}
{/* {<Appdesign/>} */}
{<Newappdesign/>}
   
    </>
  );
};

export default Firstpage;
