import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import app from "../asserts/home/app.png";
import dm from "../asserts/home/dm.png";
import seo from"../asserts/home/seo.png";
import web from "../asserts/home/web.png";
import homemobilebg from "../asserts/home/mobile-bg-home.png";
import videoBackground from "../asserts/home/home-bg.mp4";
import "../home/Wedo.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Wedo() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    AOS.init();
    const handleScroll = () => {
      const pageTop = window.pageYOffset;
      const pageBottom = pageTop + window.innerHeight;
      const tags = document.querySelectorAll(".fadein");

      tags.forEach((tag) => {
        if (tag.getBoundingClientRect().top < pageBottom) {
          tag.classList.add("visible");
        } else {
          tag.classList.remove("visible");
        }
      });
    };

    // Add event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="homepage " id="home">
      <div className="top1-tit">
        <div className="example-10 video-background">
          {/* Video element for background */}
          <video autoPlay loop muted className="video-background__video">
            <source
              src={videoBackground}
              type="video/mp4"
              className="homeVideo"
            />
          </video>
          <div className="bgcontainer">
            <div className="overlay">
              <div className="container  container-homepage ">
                <h1 data-aos="zoom-in">
                 <span className="our">RESEARCH </span> <span className="ser">AND</span> <br />
                  <span className="our">DEVELOPMENT </span><span className="ser">COMPANY</span>
                </h1>
                <p >
                  Our company specializes in providing innovative digital
                  marketing
                  <br />
                  solutions tailored to elevate your brand's online presence and
                  drive business growth.
                </p>
                <button class="buttonarrow" >
                <span className="hover-underline-animation">
  <Link to="/Secondpage" className="button-home-mobile">
    CONTACT US
  </Link>
</span>
                 <svg
                id="arrow-horizontal"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="10"
                viewBox="0 0 46 16"
                fill=" #005B94 "
                
              >
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                      transform="translate(30)"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-background v">
        <section
          className="homebg"
          style={{
            backgroundImage: `url(${homemobilebg})`,
           
          }}
        >
          <div className="container  container-homepage-mobile">
            <h1>
             <span className="our " >RESEARCH</span> <span className="ser">AND</span> <br />
             <span className="our " >DEVELOPMENT </span><span className="ser">COMPANY</span>
            </h1>
            <p style={{ fontSize: '18px' }} >
              Our company specializes in providing innovative digital marketing
              <br />
              solutions tailored to elevate your brand's online presence and
              drive business growth.
            </p>
            < button class="buttonarrow-mobile">
            <span className="hover-underline-animation">
  <Link to="/Secondpage" className="button-home-mobile">
    CONTACT US
  </Link>
</span>
              <svg
                id="arrow-horizontal"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="10"
                viewBox="0 0 46 16"
                fill="#005B94 "
                
              >
                <path
                  id="Path_10"
                  data-name="Path 10"
                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                  transform="translate(30)"
                ></path>
              </svg>
            </button>
          </div>
        </section>
      </div>

      {/* <div className=" WEDO-SERVICE ">
        <h1 className=" WEDO-SERVICE-heading " data-aos="zoom-in">
          <span className="our serv "> OUR</span> SERVICE
        </h1>
      </div> */}



<>
 
 
</>


<>
<>
<div className="">
  <div className="price_cards">
    {/*CARD_BEGINING*/}
    <div className="card card_1">
      <img
        src="https://i.ibb.co/BCRGYmx/bronze.png"
        className="badge"
        alt="STATER"
      />
     <h1 className="title">Business Strategy</h1>
     <h1 className="title"></h1>
      <section>
       
        <ul>
        <li>Problem identification
</li>
          <li>Sensing Trend
</li>
          <li>  Competitor Analysis
</li>
          <li>Strategic Planning
 </li>
          <li>Data Analysis
</li>
<li className="vid">Performance Analysis</li>
        </ul>
        {/* <button className="btn1">Learn more</button> */}
      </section>
    </div>
    {/*CARD_ENDS*/}
    {/*CARD_BEGINING*/}
    <div className="card card_2">
      <img
        src="https://i.ibb.co/DQR0Mzt/silver.png"
        className="badge"
        alt="PREMIUM"
      />
    <h1 className="title">Marketing Strategy</h1>
    
      <h1 className="title"></h1>
      <section>
       
        <ul>
        <li>Website Design</li>
          <li>SEO</li>
          <li>Content Marketing</li>
          <li>Lead generation</li>
          <li>Social Media Marketing</li>
          <li>E-Commerce Website</li>
        </ul>
        {/* <button className="btn1">Learn more</button> */}
      </section>
    </div>
    {/*CARD_ENDS*/}
    {/*CARD_BEGINING*/}
    <div className="card card_3">
      <img
        src="https://i.ibb.co/Nmh4LR0/gold.png"
        className="badge"
        alt="UNLIMITED"
      />
      <h1 className="title">Operational Strategy</h1>
      <h1 className="title"></h1>
      <section>
       
        <ul>
        <li>CRM</li>
          <li>   Financial management</li>
          <li>Technology Adaption</li>
          <li>Resource-upgradation</li>
          <li> Content Marketing</li>
          <li>Strategic Reviews</li>
        </ul>
        {/* <button className="btn1">Learn more</button> */}
      </section>
    </div>
    {/*CARD_ENDS*/}
  </div>
  </div>
  
</>
 
</>
<div className="container-fluid">
  <h1 className="text-center mt-5 display-3 fw-bold">
    Our <span className="theme-text">Works</span>
  </h1>
  <hr className="mx-auto mb-5 w-25" />
  <div className="row mb-5">
    <div className="col-12 col-sm-6 col-md-3 m-auto">
      {/* card starts here */}
      <div className=" shadow    cds">
        <img src={web} alt="" className="card-img-top" />
        <div className="card-body">
          <h3 className="text-center vid">Software Development</h3>
          <hr className="mx-auto w-75" />
          <p className="elit">
          We specialize in custom software development, increase the efficiency for your business.
          </p>
        </div>
      </div>
      {/* card ends here */}
    </div>
    {/* col ends here */}
    <div className="col-12 col-sm-6 col-md-3 m-auto">
      {/* card starts here */}
      <div className=" shadow cds">
        <img src={app}  alt="" className="card-img-top" />
        <div className="card-body">
          <h3 className="text-center vid">Database Management</h3>
          <hr className="mx-auto w-75" />
          <p className="elit">
          From building strong data security, and high storage convenience, to preventing data loss
          </p>
        </div>
      </div>
      {/* card ends here */}
    </div>
    {/* col ends here */}
    
    <div className="col-12 col-sm-6 col-md-3 m-auto">
      {/* card starts here */}
      <div className=" shadow cds">
        <img src={dm} alt="" className="card-img-top" />
        <div className="card-body">
          <h3 className="text-center">Digital Marketing</h3>
          <hr className="mx-auto w-75" />
          <p className="elit">
          We enhance your online presence by driving organic traffic and boosting your company growth.
          </p>
        </div>
      </div>
      {/* card ends here */}
    </div>
    {/* col ends here */}
    <div className="col-12 col-sm-6 col-md-3 m-auto">
      {/* card starts here */}
      <div className=" shadow cds">
        <img src={seo} alt="" className="card-img-top" />
        <div className="card-body">
          <h3 className="text-center">Video Advertisement</h3>
          <hr className="mx-auto w-75" />
          <p className="elit">
          Elevate your brand with our innovative solutions.
Experience excellence with our trusted services.

          </p>
        </div>
      </div>
      {/* card ends here */}
    </div>
    {/* col ends here */}
  </div>
</div>






















<h1 className="user0"><span className="our">Why</span> Choose Us</h1>



          <>
  
  
</>

<section className="choosee">
<section className="container-fluid">
  <div className="choose">
<div class="row">
  <div class="col why ">
    <div class="icon-circle">
      <i class="fas fa-lightbulb icon vbnx"></i>
    </div>
    <h2 className="text-center serv">Creativity</h2>
                     
    <p className="text-center" > Our designers are passionate about pushing the boundaries of creativity to deliver designs that stand out. </p>
  </div>
  <div class="col why yes">
    <div class="icon-circle">
    <i class="fas fa-users icon vbn"></i>
    </div>
    <h2 className="text-center serv vid">User-Centric <span className="serc vid">Approach</span></h2>
    <p  className="text-center"> We prioritize the needs and preferences of your target audience to create designs that resonate with them.</p>
  </div>

  <div class="col why whys tion">
    <div class="icon-circle">
      <i class="fas fa-search icon vbns"></i>
    </div>
    <h2 className="text-center serv ">Attention to <span className="serc">Detail</span></h2>
    <p className="text-center"> We believe that every pixel matters. Our meticulous attention to detail ensures that every design element is carefully crafted for maximum impact.</p>
  </div>
  <div class="col why  whyss tions">
    <div class="icon-circle">
      <i class="fas fa-hands-helping icon"></i>
    </div>
    <h2 className="text-center serv ">Collaborative <span className="serc">Process</span></h2>
    <p className="text-center">
    
    We believe in collaboration and work closely with you throughout the design process to ensure that your vision is brought to life.</p>
  </div>
</div>
</div>
</section>

</section>



<section class="about" id="about">
            <div class="container">
                <div class="heading text-center">
                    <h2 className="serv">About <span className="serc"> Us</span></h2>
                    <p>At our company, we excel in delivering forward-thinking digital marketing strategies crafted to amplify your brand's online visibility and fuel business advancement.
                       
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <img src="https://i.ibb.co/qpz1hvM/About-us.jpg" alt="about" class="img-fluid" width="100%"/>
                    </div>
                    <div class="col-lg-6">
                        <h3 className="pgs">PRO GLOBAL SOLUTIONS </h3>
                        <p>Pro Global Solutions is a full service product and digital marketing company based in madurai,tamilnadu,india.In today's digital business world,you need a partner who can help you take advantage of technology and marketing opportunities across a variety .Branups is a startup with high quality tech team and a marketing team where,we can develop any products in web,apps,AR,VR and we can also work on amzon alexa.</p>
                        <div class="row">
                            <div class="col-md-6">
                                <h4>
                            <i class="fa-regular fa-circle-check fa-xs"></i>App Development</h4>
                            </div>
                            <div class="col-md-6">
                                <h4>
                            <i class="fa-regular fa-circle-check fa-xs"></i>
                                    Digital Marketing & Branding</h4>
                            </div>
                            <div class="col-md-6">
                                <h4>
                            <i class="fa-regular fa-circle-check fa-xs"></i>Web Development</h4>
                            </div>
                            <div class="col-md-6">
                                <h4>
                            <i class="fa-regular fa-circle-check fa-xs"></i>Creative Design
                                   </h4>
                            </div>
                            <div class="col-md-6">
                                <h4>
                            <i class="fa-regular fa-circle-check fa-xs"></i>Video Advertisement</h4>
                            </div>
                            <div class="col-md-6">
                                <h4>
                            <i class="fa-regular fa-circle-check fa-xs"></i>
                                    Speed And Flexibility</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


       
    </div>
  );
  
}

export default Wedo;
