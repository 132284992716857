import React, { useEffect, useRef } from "react";
import "../whastapp/Whastapp.css"
const Whatsapp = () => {
  const menuToggleRef = useRef(null);
  const wrapperMenuRef = useRef(null);
  const closeWARef = useRef(null);
  const inputsRef = useRef([]);

  useEffect(() => {
    const menuToggle = menuToggleRef.current;
    const wrapperMenu = wrapperMenuRef.current;
    const closeWA = closeWARef.current;
    const inputs = inputsRef.current;

    if (menuToggle && wrapperMenu && closeWA) {
      menuToggle.onclick = function () {
        wrapperMenu.classList.toggle('active');
      };
      closeWA.onclick = function () {
        wrapperMenu.classList.remove('active');
      };
    }

    inputs.forEach(input => {
      input.addEventListener('input', function () {
        var bg = this.value ? 'show' : 'none';
        this.setAttribute('class', bg);
      });
    });

    return () => {
      if (menuToggle) menuToggle.onclick = null;
      if (closeWA) closeWA.onclick = null;
      inputs.forEach(input => input.removeEventListener('input', null));
    };
  }, []);

  const sendToWhatsApp = () => {
    /* Input Form */
    var name = document.getElementById("cName").value;
    var email = document.getElementById("cEmail").value;
    var subject = document.getElementById("cSubject").value;
    var message = document.getElementById("cMessage").value;
    var postLink = window.location.href;
    /* Validation for Required Columns */
    var error_name = document.getElementById("error_name"),
      error_email = document.getElementById("error_email"),
      error_message = document.getElementById("error_message");
    var text;
    if (name === "") {
      text = 'Please enter your name';
      error_name.setAttribute('data-text', text);
      return false;
    }
    if (email.indexOf("@") === -1 || email.length < 6) {
      text = 'Please enter valid email';
      error_email.setAttribute('data-text', text);
      return false;
    }
    if (message === "") {
      text = 'Please enter your message';
      error_message.setAttribute('data-text', text);
      return false;
    }
    /* URL Final Whatsapp */
    var msg = "New message from " + name + "\n\n"; // Opening Message
    msg += "*Name:* " + name + "\n";
    msg += "*Email:* " + email + "\n";
    msg += "*Subject:* " + subject + "\n";
    msg += "*Message:* " + message + "\n\n";
    msg += "=============================" + "\n";
    msg += "*Form:* " + postLink + "\n";
    msg += "=============================";
    /* WhatsApp Settings */
   /* WhatsApp Settings */
   var walink = 'https://api.whatsapp.com/send?',
    phoneNumber = '+919360620595  ';
 // Replace with your actual WhatsApp number including the country code

var encodedMessage = encodeURIComponent(msg);
var whatsappUrl = walink + "phone=" + phoneNumber + "&text=" + encodedMessage;
window.open(whatsappUrl, '_blank');
return true;
  };
  return (
    <>
      <div className="nav-whatsapp" ref={wrapperMenuRef}>
        <div className="wrapperWA">
          <div className="wrapperWA-header">
            <h2>WhatsApp Live Chat</h2>
            <div className="closeWA" ref={closeWARef}>
              <svg className="h-6 w-6" fill="none" stroke="#f40076" viewBox="0 0 24 24">
                <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
              </svg>
            </div>
          </div>
          <div className="form-container" id="waform-IT">
            <div className="formC">
              <div className="Fcontrol">
                <input className="cName" id="cName" name="name" required="required" type="text" ref={el => inputsRef.current[0] = el} />
                <span className="nameC">Name</span>
                <span className="valid" id="error_name" />
              </div>
              <div className="Fcontrol">
                <input className="cEmail" id="cEmail" name="email" required="required" type="email" ref={el => inputsRef.current[1] = el} />
                <span className="emailC">Email</span>
                <span className="valid" id="error_email" />
              </div>
            </div>
            <div className="formC">
              <div className="Fcontrol">
                <select className="cSubject" id="cSubject">
                  <option value="Help">Help</option>
                  <option value="Question">Question</option>
                  <option value="Request">Request</option>
                </select>{" "}
                <span className="subjectC">Select Subject</span>
              </div>
              <div className="Fcontrol">
                <textarea className="cMessage" id="cMessage" name="message" required="required" ref={el => inputsRef.current[2] = el} />
                <span className="messageC">Message</span>
                <span className="valid" id="error_message" />
              </div>
            </div>
            <div className="formB">
              <a className="whatsapp-send" onClick={sendToWhatsApp}>
                <svg viewBox="0 0 32 32">
                  <path d="M16 2a13 13 0 0 0-8 23.23V29a1 1 0 0 0 .51.87A1 1 0 0 0 9 30a1 1 0 0 0 .51-.14l3.65-2.19A12.64 12.64 0 0 0 16 28a13 13 0 0 0 0-26Zm0 24a11.13 11.13 0 0 1-2.76-.36 1 1 0 0 0-.76.11L10 27.23v-2.5a1 1 0 0 0-.42-.81A11 11 0 1 1 16 26Z" />
                  <path d="M19.86 15.18a1.9 1.9 0 0 0-2.64 0l-.09.09-1.4-1.4.09-.09a1.86 1.86 0 0 0 0-2.64l-1.59-1.59a1.9 1.9 0 0 0-2.64 0l-.8.79a3.56 3.56 0 0 0-.5 3.76 10.64 10.64 0 0 0 2.62 4 8.7 8.7 0 0 0 5.65 2.9 2.92 2.92 0 0 0 2.1-.79l.79-.8a1.86 1.86 0 0 0 0-2.64Zm-.62 3.61c-.57.58-2.78 0-4.92-2.11a8.88 8.88 0 0 1-2.13-3.21c-.26-.79-.25-1.44 0-1.71l.7-.7 1.4 1.4-.7.7a1 1 0 0 0 0 1.41l2.82 2.82a1 1 0 0 0 1.41 0l.7-.7 1.4 1.4Z" />
                </svg>
                Send WhatsApp
              </a>
            </div>
          </div>
        </div>
        <div className="whatsapp-float" ref={menuToggleRef}>
          <div className="whatsapp-icon">
            <svg viewBox="0 0 512 512">
              <path d="M414.73 97.1A222.14 222.14 0 0 0 256.94 32C134 32 33.92 131.58 33.87 254a220.61 220.61 0 0 0 29.78 111L32 480l118.25-30.87a223.63 223.63 0 0 0 106.6 27h.09c122.93 0 223-99.59 223.06-222A220.18 220.18 0 0 0 414.73 97.1zM256.94 438.66h-.08a185.75 185.75 0 0 1-94.36-25.72l-6.77-4-70.17 18.32 18.73-68.09-4.41-7A183.46 183.46 0 0 1 71.53 254c0-101.73 83.21-184.5 185.48-184.5a185 185 0 0 1 185.33 184.64c-.04 101.74-83.21 184.52-185.4 184.52zm101.69-138.19c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54 2.78-14.4 18-17.65 21.75-6.5 4.16-12.07 1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56 2.44-11.32c2.51-2.49 5.57-6.48 8.36-9.72s3.72-5.56 5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53 20.53 0 0 0-14.86 6.94c-5.11 5.56-19.51 19-19.51 46.28s20 53.68 22.76 57.38 39.3 59.73 95.21 83.76a323.11 323.11 0 0 0 31.78 11.68c13.35 4.22 25.5 3.63 35.1 2.2 10.71-1.59 33-13.42 37.63-26.38s4.64-24.06 3.25-26.37-5.11-3.71-10.69-6.48z" />
            </svg>
          </div>
          <span className="whatsapp-text">Talk to us?</span>
        </div>
      </div>
    
      <a
        href="https://www.imamuddinwp.com/2024/06/whatsapp-floating-button-for-website.html"
        target="_blank"
      >
       
      </a>
      
    </>
  );
};

export default Whatsapp;
