import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import logo from "../asserts/navbar/logo.png";

function Navbar() {
  const location = useLocation();

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navbarMenuClasses = `navbar-menu ${isNavbarOpen ? 'open' : ''}`;

  return (
    <nav className={`navbar navbar-expand-lg ${isScrolled ? "scrolled" : ""}`} style={{ fontFamily: "'Eirene Sans', sans-serif" }}>
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={logo} className="blackpic" alt="" />
        </Link>
        <button className={`navbar-toggler ${isNavbarOpen ? "" : "collapsed"} no-click`} type="button" onClick={toggleNavbar} aria-expanded={isNavbarOpen ? "true" : "false"}>
          <span className="spans box ser">☰</span>
        </button>
        <div className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`} id="navbarSupportedContent">
          <ul className={`navbar-nav nav-links ms-auto ${navbarMenuClasses}`}>
            <li className={`nav-item mx-4 ${location.pathname === "/" ? "active" : ""}`}>
              <Link to="/" className="nav-link" onClick={closeNavbar}>We do</Link>
            </li>
            <li className={`nav-item mx-4 ${location.pathname === "/Service" ? "active" : ""}`}>
              <Link to="/View" className="nav-link" onClick={closeNavbar}>Service</Link>
            </li>
            
            <li className={`nav-item mx-4 ${location.pathname === "/Strategy" ? "active" : ""}`}>
              <Link to="/Strategy" className="nav-link" onClick={closeNavbar}>Strategy</Link>
            </li>
            <li className={`nav-item mx-4 ${location.pathname === "/Clients" ? "active" : ""}`}>
              <Link to="/Clients" className="nav-link" onClick={closeNavbar}>Clients</Link>
            </li>
            <li className={`nav-item mx-4 ${location.pathname === "/Firstpage" ? "active" : ""}`}>
              <Link to="/Firstpage" className="nav-link" onClick={closeNavbar}>Work</Link>
            </li>
            <li className={`nav-item mx-4 ${location.pathname === "/Secondpage" ? "active" : ""}`}>
              <Link to="/Secondpage" className="nav-link" onClick={closeNavbar}>Contact us</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
