// import React, { useState, useRef } from "react";
// import { Link, useLocation } from "react-router-dom";
// import emailjs from "@emailjs/browser";
// import contact1bg from "../asserts/contact/contact-bg.jpg";
// import axios from "axios";
// import styled from "@emotion/styled";
// import { CleaningServices, ConstructionOutlined } from "@mui/icons-material";
// import "@fortawesome/fontawesome-free/css/all.min.css";

// // Now you can use styled components in your code

// const Secondpage = () => {
//   const initialState = {
//     Name: "",
//     Email: "",
//     Number: "",
//     Message: "",
//   };

//   const [user, setUser] = useState(initialState);
//   const [errors, setErrors] = useState({});

//   const data = (e) => {
//     const name = e.target.name;
//     const value = e.target.value;
//     setUser({ ...user, [name]: value });
//   };

//   const validateForm = () => {
//     let errors = {};
//     let isValid = true;

//     if (!user.Name) {
//       errors.Name = "Name is required";
//       isValid = false;
//     }

//     if (!user.Email) {
//       errors.Email = "Email is required";
//       isValid = false;
//     } else if (!/\S+@\S+\.\S+/.test(user.Email)) {
//       errors.Email = "Email is invalid";
//       isValid = false;
//     }

//     if (!user.Number) {
//       errors.Number = "Phone number is required";
//       isValid = false;
//     } else if (!/^\d{10}$/.test(user.Number)) {
//       errors.Number = "Phone number is invalid";
//       isValid = false;
//     }

//     if (!user.Message) {
//       errors.Message = "Message is required";
//       isValid = false;
//     }

//     setErrors(errors);
//     return isValid;
//   };

//   // const getdata = async (e) => {
//   //   e.preventDefault();

//   //   if (validateForm()) {
//   //     const options = {
//   //       method: "POST",
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //       },
//   //       body: JSON.stringify({
//   //         Name: user.Name,
//   //         Email: user.Email,
//   //         Number: user.Number,
//   //         Message: user.Message,
//   //       }),
//   //     };

//   //     const res = await fetch(
//   //       "https://reactfirebase-e1fdf-default-rtdb.firebaseio.com/UserData.json",
//   //       options
//   //     );
//   //     console.log(res);

//   //     if (res.ok) {
//   //       alert("Message Sent");
//   //       // Clear the form fields after successful submission
//   //       setUser(initialState);
//   //     } else {
//   //       alert("Error Occurred");
//   //     }
//   //   }
//   // };
//   const form = useRef();
  
//   const FormSubmit = async(e) => {
//     e.preventDefault();
//     // console.log("hi")
//     if (validateForm()) {
//       const options = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           Name: user.Name,
//           Email: user.Email,
//           Number: user.Number,
//           Message: user.Message,
//         }),
//       };

//       const res = await fetch(
//         "https://company-web-b6896-default-rtdb.asia-southeast1.firebasedatabase.app/UserData.json",
     
//         options
//       );
//       console.log(res);

//       if (res.ok) {
//         alert("Message Sent");
//         // Clear the form fields after successful submission
//         setUser(initialState);
//       } else {
//         alert("Error Occurred");
//       }
//     }
//     emailjs
//       .sendForm("service_h52yg1v", "template_glhu2dp", form.current, {
//         publicKey: "Sd1CTMUtTMXNqHLzY",
//       })
//       .then(
//         (result) => {
//           console.log(result.text);
//           console.log(message.send);
//         },
//         (error) => {
//           console.log( error.text);
//         }
//       );
//   };

//   return (
//     <section className="contact">
//       <section
//         className="contactbg"
//         style={{
//           backgroundImage: `url(${contact1bg})`,
//         }}
//       >
//         <div className="contactHead">
//           <div className="container">
//             <h1 className="contactHead1 ">
//               <span className="our">CONTACT </span>{" "}
//               <span className="contact-head-sub ser">US</span>
//             </h1>
//           </div>
//         </div>
//       </section>
//       <div className="heading-contact">
//         <h1 className="contact-h1 text " style={{ color: "white" }}>
//           How can we assist you in this case? Let's talk.
//         </h1>
//       </div>
//       <div className=" contact-Container">
//         <span className="big-circle" />
//         <img src="img/shape.png" className="square" alt="" />
//         <div className="form">
//           <div className="contact-info">
//             <h3 className="title">Let's get in touch</h3>
//             <p
//               className="text  t1 "
//               style={{ fontWeight: "normal", fontSize: "17px" }}
//             >
//               ProGlobalSolutions offers web development and digital marketing &
//               Videos services to help businesses improve their online presence
//               and achieve success.
//             </p>
//             <div className="info">
//               <div className="information">
//                 <i className="fas fa-map-marker-alt" /> &nbsp; &nbsp;
//                 <p style={{ fontWeight: "normal", fontSize: "17px" }}>
//                   F3, 1/113, Jmj Complex, Pro Global Solutions, TNAU Nagar,
//                   Otthakadai, Madurai, Tamil Nadu 625107
//                 </p>
//               </div>
//               <div className="information">
//                 <i className="fas fa-envelope" /> &nbsp; &nbsp;
//                 <p style={{ fontWeight: "normal", fontSize: "16px" }}>
//                 proglobalsolutions.pgs@gmail.com
//                 </p>
//               </div>
//               <div className="information">
//                 <i className="fas fa-phone" />
//                 &nbsp;&nbsp;
//                 <p style={{ fontWeight: "normal", fontSize: "17px" }}>
//                   +91 93606 20595{" "}
//                 </p>
//               </div>
//             </div>
//             <div className="social-media">
//               <p style={{ fontWeight: "normal", fontSize: "17px" }}>
//                 Connect with us :
//               </p>
//               <div className="social-icons">
//                 <a href="https://www.linkedin.com/company/pgs-in">
//                   <i className="fab fa-linkedin-in"></i>
//                 </a>
//                 <a href="https://www.instagram.com/proglobalsolutions.in/">
//                   <i className="fab fa-instagram"></i>
//                 </a>
//                 <a href="https://wa.me/9360620595">
//                   <i className="fab fa-whatsapp"></i>
//                 </a>
//                 <a href="https://www.youtube.com/@ProGlobalSolutions">
//                   <i className="fab fa-youtube"></i>
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="contact-form">
//             <form ref={form} onSubmit={FormSubmit} method="POST">
//               <span className="circle one" />
//               <span className="circle two" />
//               <h3 className="title">Contact us</h3>
//               <div className="input-container  mnl" style={{ color: "white" }}>
//                 <input
//                   type="text"
//                   name="Name"
//                   className="input"
//                   placeholder="Your Name"
//                   value={user.Name}
//                   required
//                   onChange={data}
//                   style={{ color: "white" }}
//                 />
//                 {errors.Name && <p style={{ color: "red" }}>{errors.Name}</p>}
//               </div>
//               <div className="input-container con-nn">
//                 <input
//                   type="email"
//                   name="Email"
//                   className="input"
//                   placeholder="Email"
//                   value={user.Email}
//                   required
//                   onChange={data}
//                   style={{ color: "black" }}
//                 />
//                 {errors.Email && <p style={{ color: "red" }}>{errors.Email}</p>}
//               </div>
//               <div className="input-container con-nn">
//                 <input
//                   type="text"
//                   name="Number"
//                   className="input"
//                   placeholder="Phone Number"
//                   value={user.Number}
//                   required
//                   onChange={data}
//                   style={{ color: "white" }}
//                 />
//                 {errors.Number && (
//                   <p style={{ color: "red" }}>{errors.Number}</p>
//                 )}
//               </div>
//               <div className="input-container textarea">
//                 <textarea
//                   name="Message"
//                   className="input"
//                   defaultValue={""}
//                   placeholder="Send Message"
//                   value={user.Message}
//                   onChange={data}
//                 />
//                 {errors.Message && (
//                   <p style={{ color: "red" }}>{errors.Message}</p>
//                 )}
//               </div>
//               <button value="Send"
//               // onClick={getdata}
//                type="submit"> 
//                 Submit
//               </button>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Secondpage;










import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import contact1bg from "../asserts/contact/contact-bg.jpg";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Secondpage = () => {
  const initialState = {
    Name: "",
    Email: "",
    Number: "",
    Message: "",
  };

  const [user, setUser] = useState(initialState);
  const [errors, setErrors] = useState({});

  const data = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!user.Name) {
      errors.Name = "Name is required";
      isValid = false;
    }

    if (!user.Email) {
      errors.Email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(user.Email)) {
      errors.Email = "Email is invalid";
      isValid = false;
    }

    if (!user.Number) {
      errors.Number = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(user.Number)) {
      errors.Number = "Phone number is invalid";
      isValid = false;
    }

    if (!user.Message) {
      errors.Message = "Message is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const form = useRef();

  const FormSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Name: user.Name,
          Email: user.Email,
          Number: user.Number,
          Message: user.Message,
        }),
      };

      const res = await fetch(
        "https://company-web-b6896-default-rtdb.asia-southeast1.firebasedatabase.app/UserData.json",
        options
      );
      console.log(res);

      if (res.ok) {
        alert("Message Sent");
        setUser(initialState);
      } else {
        alert("Error Occurred");
      }
    }
    emailjs
      .sendForm("service_h52yg1v", "template_glhu2dp", form.current, {
        publicKey: "Sd1CTMUtTMXNqHLzY",
      })
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="contact">
      <section
        className="contactbg"
        style={{
          backgroundImage: `url(${contact1bg})`,
        }}
      >
        <div className="contactHead">
          <div className="container">
            <h1 className="contactHead1 ">
              <span className="our">CONTACT </span>{" "}
              <span className="contact-head-sub ser">US</span>
            </h1>
          </div>
        </div>
      </section>
      <div className="heading-contact">
        <h1 className="contact-h1 text " style={{ color: "white" }}>
          How can we assist you in this case? Let's talk.
        </h1>
      </div>
      <div className=" contact-Container">
        <span className="big-circle" />
        <img src="img/shape.png" className="square" alt="" />
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Let's get in touch</h3>
            <p
              className="text  t1 "
              style={{ fontWeight: "normal", fontSize: "17px" }}
            >
              ProGlobalSolutions offers web development and digital marketing &
              Videos services to help businesses improve their online presence
              and achieve success.
            </p>
            <div className="info">
              <div className="information">
                <i className="fas fa-map-marker-alt" /> &nbsp; &nbsp;
                <p style={{ fontWeight: "normal", fontSize: "17px" }}>
                  F3, 1/113, Jmj Complex, Pro Global Solutions, TNAU Nagar,
                  Otthakadai, Madurai, Tamil Nadu 625107
                </p>
              </div>
              <div className="information">
                <i className="fas fa-envelope" /> &nbsp; &nbsp;
                <p style={{ fontWeight: "normal", fontSize: "16px" }}>
                  proglobalsolutions.pgs@gmail.com
                </p>
              </div>
              <div className="information">
                <i className="fas fa-phone" />
                &nbsp;&nbsp;
                <p style={{ fontWeight: "normal", fontSize: "17px" }}>
                  +91 93606 20595{" "}
                </p>
              </div>
            </div>
            <div className="social-media">
              <p style={{ fontWeight: "normal", fontSize: "17px" }}>
                Connect with us :
              </p>
              <div className="social-icons">
                <a href="https://www.linkedin.com/company/pgs-in">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="https://www.instagram.com/proglobalsolutions.in/">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://wa.me/9360620595">
                  <i className="fab fa-whatsapp"></i>
                </a>
                <a href="https://www.youtube.com/@ProGlobalSolutions">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="contact-form">
            <form ref={form} onSubmit={FormSubmit} method="POST">
              <span className="circle one" />
              <span className="circle two" />
              <h3 className="title">Contact us</h3>
              <div className="input-container  mnl" style={{ color: "white" }}>
                <input
                  type="text"
                  name="Name"
                  className="input"
                  placeholder="Your Name"
                  value={user.Name}
                  required
                  onChange={data}
                  style={{ color: "white" }}
                />
                {errors.Name && <p style={{ color: "red" }}>{errors.Name}</p>}
              </div>
              <div className="input-container con-nn">
                <input
                  type="email"
                  name="Email"
                  className="input"
                  placeholder="Email"
                  value={user.Email}
                  required
                  onChange={data}
                  style={{ color: "black" }}
                />
                {errors.Email && <p style={{ color: "red" }}>{errors.Email}</p>}
              </div>
              <div className="input-container con-nn">
                <input
                  type="text"
                  name="Number"
                  className="input"
                  placeholder="Phone Number"
                  value={user.Number}
                  required
                  onChange={data}
                  style={{ color: "white" }}
                />
                {errors.Number && (
                  <p style={{ color: "red" }}>{errors.Number}</p>
                )}
              </div>
              <div className="input-container textarea">
                <textarea
                  name="Message"
                  className="input"
                  defaultValue={""}
                  placeholder="Send Message"
                  value={user.Message}
                  onChange={data}
                />
                {errors.Message && (
                  <p style={{ color: "red" }}>{errors.Message}</p>
                )}
              </div>
              <button
                value="Send"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Secondpage;
