import React, { useEffect } from "react";
import "../strategy/Strategy.css";
// import Moving from"../moving/Moving";
import carrer from "../asserts/secondpage/carrer.jpeg";
import MSBG from "../asserts/secondpage/MSBG.jpg";
import vv from "../asserts/secondpage/vv.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const Strategy = () => {
  useEffect(() => {
    AOS.init({ once: true }); // Initialize AOS with options
  }, []);

  return (
    <>
      <div className="">
      <section className="strategy">
        <section
          className="heros"
          style={{
           backgroundImage: ` url(${carrer})`,
            fontFamily: '"Roboto", "sans-serif"',
          }}
        >
          <div className="row bn">
            <div className="container ">
              <div className="col-lg-10">
                <div className="contents ">
                  <div className="analysis">
                    <h1
                      className="need ml-5"
                      data-aos-delay="800"
                      data-aos-duration="1000"
                      data-aos="fade-up "
                    >
                      <span className=" serv">Business </span>
                      <span className="ser">Analysis</span> <br></br>
                      <span className=" serv"> Marketing</span>{" "}
                      <span className="ser">Strategy</span> <br></br>
                      <span className=" serv"> Operational </span>
                      <span className="ser">Strategy</span>
                    </h1>

                    <p
                      className="contents vvvk"
                      data-aos-delay="800"
                      data-aos-duration="1000"
                      data-aos="fade-up"
                    >
                      Business Analysis assesses business performance, Marketing
                      Strategy crafts customer engagement plans, Operational
                      Strategy optimizes production and resource management.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>
        </section>

        <section className="FONTSS">
          {/* <section className="banner1">
            <div className="container ">
              <h2
                className="is "
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                {" "}
                <span className=" serv">Business </span>{" "}
                <span className="serc">analysis</span>
              </h2>
              <h5
                className="at"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                Business analysis enhances decision-making by providing valuable
                insights into market trends and customer behaviour, fostering
                informed choices that reduce risks and optimize outcomes.
              </h5>
            </div>
            <section className="read">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div
                      className="sec1 "
                      style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                    >
                      <h5
                        className="problem1 "
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv">PROBLEM</span>{" "}
                        <span className="serc\v">IDENTIFICATION </span>
                      </h5>
                      <p
                        className="PARAA1 "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        {" "}
                        Many businesses struggle with limited online visibility, low engagement rates, ineffective advertising spend, inconsistent brand messaging, and poor conversion rates. Addressing these challenges is crucial to enhancing your brand’s digital presence and achieving business growth.

                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1">
                      <h5
                        className="problem2"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv">TRENDING 
</span>{" "}
                        <span className="serv"> OPPORTUNITIES</span>
                      </h5>
                      <p
                        className="PARAA2  "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        Staying ahead of industry trends is essential. Key opportunities include personalized content, video marketing, influencer partnerships, voice search optimization, and interactive content. Leveraging these trends can significantly boost engagement and conversion rates.

                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1">
                      <h5
                        className="problem3"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv">SWOT </span>
                        <span className="serv">ANALYSIS</span>
                      </h5>
                      <p
                        className="PARAA3  "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        {" "}
                       Conducting a SWOT analysis helps identify strengths like innovative solutions and tailored approaches, and weaknesses such as resource allocation challenges. Opportunities include market expansion and technological advancements, while threats involve intense competition and changing algorithms. This analysis informs strategic decisions for sustained success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section> */}




          <section className="banner1">
            <div className="container">
              <h2
                className="is"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up "
              >
                {" "}
                <span className=" serv">  Business  </span>
                <span className="serc">analysis</span>
              </h2>
              <h5
                className="at"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                {" "}
                Business analysis enhances decision-making by providing valuable
                insights into market trends and customer behaviour, fostering
                informed choices that reduce risks and optimize outcomes.
              </h5>
            </div>
            <section className="read">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div
                      className="sec1"
                      style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                    >
                      <h5
                        className="problem1 Soc vid"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv">PROBLEM </span>{" "}
                        <span className="serv">IDENTIFICATION</span>
                      </h5>
                      <p
                        className="PARAA1 "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        {" "}
                        Many businesses struggle with limited online visibility, low engagement rates, ineffective advertising spend, inconsistent brand messaging, and poor conversion rates. Addressing these challenges is crucial to enhancing your brand’s digital presence and achieving business growth.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1">
                      <h5
                        className="problem2 Soc2 Soc32 vb vid"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv "> TRENDING </span>{" "}
                        <span className="serv">OPPORTUNITITY</span>
                      </h5>
                      <p
                        className="PARAA2 "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                       Staying ahead of industry trends is essential. Key opportunities include personalized content, video marketing, influencer partnerships, voice search optimization, and interactive content. Leveraging these trends can significantly boost engagement and conversion rates.


                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1 se1">
                      <h5
                        className="problem3 Soc21"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv v1"> SWOT</span>{" "}
                        <span className="serv"> ANALYSIS</span>
                      </h5>
                      <p
                        className="PARAA3 "
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        {" "}
                        Conducting a SWOT analysis helps identify strengths like innovative solutions and tailored approaches, and weaknesses such as resource allocation challenges. Opportunities include market expansion and technological advancements, while threats involve intense competition and changing algorithms.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
  







          <section
            className="hero1"
            style={{
               backgroundImage:`url(${MSBG})`,
              fontFamily: '"Roboto", "sans-serif"',
            }}
          >
            <div className="contents1s ser">
              <div className="side">
                <h1
                  data-aos-delay="800"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  <span className=" serv"> MARKETING</span>{" "}
                  <span className="ser">STRATEGY</span>
                </h1>
              </div>
              <div className="contents2s">
                <h1
                  data-aos-delay="800"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  {" "}
                </h1>
              </div>
            </div>
            <p> </p>
          </section>
          <div className="">
            {/* <section className="yellobanner">
              <p
                className="assist"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                {" "}
                Crafting a potent marketing strategy, we blend creativity with
                data-driven insights to captivate your audience. With tailored
                approaches, we carve pathways to amplify your brand's reach and
                resonance. Our strategic maneuvers navigate the digital
                landscape, ensuring your message shines amidst the noise.
              </p>
            </section> */}
          </div>
          <section className="banner1">
            <div className="container">
              <h2
                className="is"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up "
              >
                {" "}
                <span className=" serv">Marketing </span>
                <span className="serc">strategy</span>
              </h2>
              <h5
                className="at"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                {" "}
                Innovative marketing strategies tailored to your brand's
                essence. Amplify your presence, engage your audience, and ignite
                growth. We blend creativity with data to spark connections and
                drive results. Let's craft your success story together.
              </h5>
            </div>
            <section className="read">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div
                      className="sec1"
                      style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                    >
                      <h5
                        className="problem1 Soc es"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv">SOCIAL</span>{" "}
                        <span className="serc">MEDIA PROMOTION</span>
                      </h5>
                      <p
                        className="PARAA1 "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        {" "}
                        Effective social media promotion is crucial for increasing brand awareness and engagement. By leveraging platforms like Facebook, Instagram, and LinkedIn, businesses can connect with their target audience, create compelling content, and foster meaningful interactions that drive customer loyalty and growth.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1">
                      <h5
                        className="problem2 Soc2 Soc32 vb"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv"> LEAD</span>{" "}
                        <span className="serc">GENERATION</span>
                      </h5>
                      <p
                        className="PARAA2 "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                       Generating quality leads is vital for business growth. Implementing targeted campaigns, utilizing landing pages, and offering valuable content can attract potential customers. Effective lead nurturing strategies, such as personalized emails and follow-ups, convert these leads into loyal clients.

                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1 se1">
                      <h5
                        className="problem3 Soc21"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv v1"> WEBSITE </span>{" "}
                        <span className="serc">DESIGN</span>
                      </h5>
                      <p
                        className="PARAA3 "
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        {" "}
                        Optimizing your website for search engines enhances visibility and attracts organic traffic. Key SEO practices include keyword research, on-page optimization, quality content creation, and backlink building. Regularly updating and analyzing your SEO strategy ensures sustained website performance and higher search engine rankings.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          {/* operational page */}
          <section
            className="hero1"
            style={{
              backgroundImage: `url(${vv})`,
              fontFamily: '"Roboto", "sans-serif"',
            }}
          >
            <div className="side">
              <div className="contents1s">
                <h1
                  data-aos-delay="800"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                >
                  <span className=" serv"> OPERATIONAL </span>
                  <span style={{ color: "white" }}>STRATEGY</span>{" "}
                </h1>
              </div>

              <div className="contents23s">
                <h1
                  data-aos-delay="800"
                  data-aos-duration="1000"
                  data-aos="fade-up"
                ></h1>
              </div>
            </div>
            <p> </p>
          </section>
         
          {/* <section className="yellobanner">
            <p
              className="assist"
              data-aos-delay="800"
              data-aos-duration="1000"
              data-aos="fade-up"
            >
              {" "}
              Operational strategy streamlines processes, optimizes resources,
              and mitigates risks, fostering efficiency and productivity. It
              aligns day-to-day activities with broader business goals,
              cultivating a competitive edge. By prioritizing customer
              satisfaction and innovation, it drives continual improvement and
              adaptability. Ultimately, a well-executed operational strategy
              enhances stakeholder confidence and fuels organizational success.
            </p>
          </section> */}
          
          {/* <section className="banner1">
            <div className="container">
              <h2
                className="is"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                {" "}
                <span className=" serv">Operational</span>{" "}
                <span className="serc"> strategy</span>
              </h2>
              <h5
                className="at "
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                {" "}
                Operational strategy ensures efficient resource allocation and
                risk management, aligning with overarching business objectives.
                It fosters innovation, adaptability, and continual improvement,
                driving competitive advantage. By prioritizing customer
                satisfaction and employee engagement, it enhances organizational
                performance and stakeholder confidence. In essence, operational
                strategy is the blueprint for sustainable success.
              </h5>
            </div>
            <section className="read">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div
                      className="sec1"
                      style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                    >
                      <h5
                        className="problem1 Soc"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv">CUSTOMER </span>
                        <span className="serc">RELATIONSHIP </span>
                      </h5>
                      <p
                        className="PARAA1 "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up "
                      >
                        {" "}
                        Effective customer relationship management (CRM) is essential for building and maintaining strong connections with your clients. Implementing CRM systems and strategies helps in understanding customer needs, improving communication, and delivering personalized experiences that enhance customer satisfaction and loyalty.


                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1 ">
                      <h5
                        className="problem2 soc1"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv">TECHNOLOGICAL</span><span className="serc">MANAGEMENT</span>
                      </h5>
                      <p
                        className="PARAA2 "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        Leveraging advanced technology is crucial for streamlining operations and staying competitive. Integrating modern tools and software solutions enhances productivity, automates routine tasks, and provides valuable data insights. Continuous technological innovation ensures your business adapts to changing market demands.

.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1">
                      <h5
                        className="problem3 soc2  gf fb vil"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv">FINICIAL</span>{" "}
                        <span className="serc">MANAGEMENT</span>
                      </h5>
                      <p
                        className="PARAA3  P3"
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        {" "}
                        Sound financial management is fundamental to business stability and growth. By implementing robust financial planning, budgeting, and monitoring practices, businesses can ensure efficient resource allocation, reduce costs, and maximize profitability. Regular financial analysis and reporting help in making informed strategic decisions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section> */}
        </section>
      </section>
      <div></div>
    </div>




    <section className="banner1">
            <div className="container">
              <h2
                className="is"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up "
              >
                {" "}
                <span className=" serv"> Operational  </span>
                <span className="serc">strategy</span>
              </h2>
              <h5
                className="at"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos="fade-up"
              >
                {" "}
                Operational strategy ensures efficient resource allocation and
                risk management, aligning with overarching business objectives.
                It fosters innovation, adaptability, and continual improvement,
                driving competitive advantage. By prioritizing customer
                satisfaction and employee engagement, it enhances organizational
                performance and stakeholder confidence. In essence, operational
                strategy is the blueprint for sustainable success..
              </h5>
            </div>
            <section className="read">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div
                      className="sec1"
                      style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                    >
                      <h5
                        className="problem1 Soc vbf"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv">CRM </span>{" "}
                        <span className="serc"></span>
                      </h5>
                      <p
                        className="PARAA1 "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        {" "}
                        Effective customer relationship management (CRM) is essential for building and maintaining strong connections with your clients. Implementing CRM systems and strategies helps in understanding customer needs, improving communication, and delivering personalized experiences that enhance customer satisfaction and loyalty.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1">
                      <h5
                        className="problem2 Soc2 Soc32 vb vid"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv "> TECHNOLOGICAL</span>{" "}
                        <span className="serc"></span>
                      </h5>
                      <p
                        className="PARAA2 "
                        style={{ fontFamily: "'SuisseIntl', sans-serif" }}
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                       Leveraging advanced technology is crucial for streamlining operations and staying competitive. Integrating modern tools and software solutions enhances productivity, automates routine tasks, and provides valuable data insights. Continuous technological innovation ensures your business adapts to changing market demands.


                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="sec1 se1">
                      <h5
                        className="problem3 Soc21"
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        <span className=" serv v1"> Financial </span>{" "}
                        <span className="serv"> management</span>
                      </h5>
                      <p
                        className="PARAA3 "
                        data-aos-delay="800"
                        data-aos-duration="1000"
                        data-aos="fade-up"
                      >
                        {" "}
                        Sound financial management is fundamental to business stability and growth. By implementing robust financial planning, budgeting, and monitoring practices, businesses can ensure efficient resource allocation, reduce costs, and maximize profitability. Regular financial analysis and reporting help in making informed strategic decisions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
  
     
      
    </>
  );
};
export default Strategy;
