import React from 'react';
import './Newappdesign.css'; // Import your CSS file

import Appdesign from "../worksp/appdesign";
import Handleslide from '../handleslide/Handleslide';
// Make sure to import the specific icon you want to use 
// Example for the Play Store icon


const Newappdesign = () => {
 



  return (
  <>
    <section className="c-section">
  <h2 className="c-section__title">
    <span className='jo' >Applications</span>
   
  </h2>
  
  <ul className="c-services">
    <li className="c-services__item">
      <h3>Responsive Web Design</h3>
      <p>
        We leverage the concept of mobile-first design. Through our work, we
        focus on designing an experience that works across different screen
        sizes.
      </p>
    </li>
    <li className="c-services__item">
      <h3>UX Auditing</h3>
      <p>
        If you are unsure of how your app behaves, we can help by doing a
        detailed UX audit that will highlight most of the issues in your
        product. From there, we can take it further and fix all issues.
      </p>
    </li>
    <li className="c-services__item">
      <h3>Front End Development</h3>
      <p>
        We are Front End masters with a deep focus on HTML, CSS. The result of
        our work is a responsive, accessible, and performant websites. Either
        you have the design ready and want us to code it, or you want us to do
        both design and code, we’re happy to do so.
      </p>
    </li>
    <li className="c-services__item">
      <h3>UX Consultation</h3>
      <p>
        If you don’t know what kind of service to request from us, don’t worry.
        We can help and see what fits your business and your budget.
      </p>
    </li>
    <li className="c-services__item">
      <h3>Cross Platform Development</h3>
      <p>
      Cross-platform apps feature shared code that can be used on many systems. By targeting multiple platforms with a single codebase, businesses can reach a broader audience, maximizing their market presence without the need for platform-specific development
      </p>
    </li>
    <li className="c-services__item">
      <h3>UX Research</h3>
      <p>
        It’s important to research deeply for the product you want to build. We
        help in that by defining the user audience, working on user stories,
        competitive analysis and much more.{" "}
      </p>
    </li>
  </ul>
</section>


<section className="mob-tit">

<>
  <div className="services">
    <div className="container">
    <h1 className='mobi-fea'>Our Mobile App Features</h1>
      <div className="row">
        <div className="col-md-4">
          <div className="services-box">
            <span className="icon">
              <i className="fa fa-pencil" />
            </span>
            {/* .Icon ends here */}
            <div className="servicen-content">
              <h4>Performance Level</h4>
              <p>
              Responsive design ensures websites seamlessly adjust to any screen size, boosting user experience on all devices.
              </p>
            </div>
            {/* .Service-content ends here */}
          </div>
          {/* .Services-box ends here */}
          <div className="services-box">
            <span className="icon">
              <i className="fa fa-rocket" />
            </span>
            {/* .Icon ends here */}
            <div className="servicen-content">
              <h4>Real time users</h4>
              <p>
               Mostly these mobile apps are designed for real time users in order to carry out real time tasks
              </p>
            </div>
            {/* .Service-content ends here */}
          </div>
          {/* .Services-box ends here */}
          <div className="services-box">
            <span className="icon">
              <i className="fa fa-sliders" />
            </span>
            {/* .Icon ends here */}
            <div className="servicen-content">
              <h4>Advance Security</h4>
              <p>
              This focuses on the sense of security and trust the app's features provide users.
              </p>
            </div>
            {/* .Service-content ends here */}
          </div>
          {/* .Services-box ends here */}
        </div>
        {/* .Col ends here */}
        <div className="col-md-4">
           


<div className='phones'>
    <div className="">
        {/* <div className="phone-screen">
            <div className="clock">{getCurrentTime()}</div>
            <video className="responsive-video" controls>
                <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            
        </div> */}
        <img
            className="img-responsive"
             src="http://pdadb.net/img/gallery/big/apple_iphone_6_touchid_single_touch.png"
            // src={iii}
            alt=""
          />
    </div>
</div>

        </div>
        
        <div className="col-md-4  ">
          <div className="services-box  pho">
            <span className="icon">
              <i className="fa fa-laptop" />
            </span>
            {/* .Icon ends here */}
            <div className="servicen-content ">
              <h4>Super Fast Speed</h4>
              <p>
              This emphasizes the app's responsiveness and quickness.This is a catchy and general term for extreme speed.
              </p>
            </div>
            {/* .Service-content ends here */}
          </div>
          {/* .Services-box ends here */}
          <div className="services-box pho2">
            <span className="icon">
              <i className="fa fa-sliders" />
            </span>
            {/* .Icon ends here */}
            <div className="servicen-content">
              <h4>Optimal Performance:</h4>
              <p>
              This focuses on the smooth and seamless execution of tasks, even demanding ones, without any lag or struggle.
              </p>
            </div>
            {/* .Service-content ends here */}
          </div>
          {/* .Services-box ends here */}
          <div className="services-box pho1">
            <span className="icon">
              <i className="fa fa-sliders" />
            </span>
            {/* .Icon ends here */}
            <div className="servicen-content">
              <h4> Offline Access</h4>
              <p>
              Access important information even when you're offline with our offline mode feature.
              </p>
            </div>
            {/* .Service-content ends here */}
          </div>
          {/* .Services-box ends here */}
        </div>
        {/* .Col ends here */}
      </div>
      {/* .Row ends here */}
    </div>
    {/* .Container ends here */}
  </div>
  {/* .Services ends here */}
  
</>

</section>
{/* <section className="sec-content">
  
<div className="container">
<div className='row'>
  <div className='col-lg-6'>
      <div className="image-section">
        <div className="circular-image-container">
          <img src={air} alt="Mobile" className="mobile-image" />
        </div>
      </div>
      </div>
      <div className='col-lg-6'>
      <div className="content-section">
        <h1 className="heading">Download Our App</h1>
        <p className="content">This is some content that will be displayed on the right side of the image. This section can be styled as needed to make it more visually appealing.</p>
       
        <Button 
            variant="primary" 
            size="lg" 
            active 
            style={{ border: 'none' }} // Remove default styles
            className="no-hover" // Add a custom class to target the button
        >
            <FontAwesomeIcon icon={faGooglePlay} style={{ color: "#ffffff" }} /> Play Store
        </Button>
      </div>
    </div>
    </div>
    </div>
    </section> */}

{/* mob-app content */}


{/* {<Appdesign/>} */}
{<Handleslide/>}

     
  





    </>


    






  );
}

export default Newappdesign;
