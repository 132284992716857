import React, { useEffect } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServicestack } from "@fortawesome/free-brands-svg-icons";
import background from "../asserts/service/service-bg-1.avif";
import "remixicon/fonts/remixicon.css";
import "../View/View.css";
import {
  RiMacbookLine,
  RiStore3Line,
  RiDatabase2Line,
  RiPaletteLine,
  RiAndroidLine,
  RiAccountBoxLine,
} from "react-icons/ri";
import AOS from "aos";
import "aos/dist/aos.css";

const View = () => {
  let i = 2;

  $(document).ready(function () {
    var radius = 200;
    var fields = $(".itemDot");
    var container = $(".dotCircle");
    var width = container.width();
    radius = width / 2.5;

    var height = container.height();
    var angle = 0,
      step = (2 * Math.PI) / fields.length;
    fields.each(function () {
      var x = Math.round(
        width / 2 + radius * Math.cos(angle) - $(this).width() / 2
      );
      var y = Math.round(
        height / 2 + radius * Math.sin(angle) - $(this).height() / 2
      );
      if (window.console) {
        console.log($(this).text(), x, y);
      }

      $(this).css({
        left: x + "px",
        top: y + "px",
      });
      angle += step;
    });

    $(".itemDot").click(function () {
      var dataTab = $(this).data("tab");
      $(".itemDot").removeClass("active");
      $(this).addClass("active");
      $(".CirItem").removeClass("active");
      $(".CirItem" + dataTab).addClass("active");
      i = dataTab;

      $(".dotCircle").css({
        transform: "rotate(" + (360 - (i - 1) * 36) + "deg)",
        transition: "2s",
      });
      $(".itemDot").css({
        transform: "rotate(" + (i - 1) * 36 + "deg)",
        transition: "1s",
      });
    });

    setInterval(function () {
      var dataTab = $(".itemDot.active").data("tab");
      if (dataTab > 6 || i > 6) {
        dataTab = 1;
        i = 1;
      }
      $(".itemDot").removeClass("active");
      $('[data-tab="' + i + '"]').addClass("active");
      $(".CirItem").removeClass("active");
      $(".CirItem" + i).addClass("active");
      i++;

      $(".dotCircle").css({
        transform: "rotate(" + (360 - (i - 2) * 36) + "deg)",
        transition: "2s",
      });
      $(".itemDot").css({
        transform: "rotate(" + (i - 2) * 36 + "deg)",
        transition: "1s",
      });
    }, 5000);
  });

  useEffect(() => {
    AOS.init();
  });
  return (
    <>
      <div className="service">
        <section
          className="bgimage"
          style={{
            backgroundImage: ` url(${background})`,
          }}
        >
          <div className="AppHead">
            <div className="container">
              <h1 className="AppHead1">
                <span class="our"> OUR</span>{" "}
                <span className="app-head-sub ser">SERVICE</span>
              </h1>
            </div>
          </div> 
            {/* <div className="workHead">
            <div className="container">
              <h1 className="workHead1 head1 " style={{ textAlign: "center" ,marginTop:"",fontWeight:"800"}}>
              <span className="our" >OUR   </span>   <span className="work-head-sub ser "  style={{ fontWeight:"800"}}>  SERVICE</span>
              </h1>
            </div>
          </div> */}
        </section>

        <section className="service-section">
          <div class="elementorcontent">
            <h2
              class="elementortitle"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false"
            >
              <span className="our">Our</span>
              <span className="our"> Service</span>{" "}
            </h2>
            <p
              class="elementordescription"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false"
            >
              where we empower businesses to thrive in the digital landscape. In
              today's fast-paced world, having a strong online presence is
              essential for success. We understand the challenges businesses
              face in navigating the complexities of the digital realm, which is
              why we offer comprehensive solutions tailored to your unique
              needs..{" "}
            </p>
          </div>

          {/* <section className="container">
          <div className="row vicebox">
            <div className="col-lg-6">

          <div class="ele">
            <div class="ele1">
              {" "}
              <div class="elementocontainer">
                <ul class="elementitems">
                  <li
                    class="elementoritem"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                  >
                    <span class="elementortext">
                      <FontAwesomeIcon
                        icon={faServicestack}
                        aria-hidden="true"
                        className="service-icon"
                      />{" "}
                      A unique website that differentiates you from the
                      competition.
                    </span>
                    <span class="elementoricon "></span>
                  </li>
                  <li
                    class="elementoritem"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                  >
                    <span class="elementortext">
                      <FontAwesomeIcon
                        icon={faServicestack}
                        aria-hidden="true"
                        className="service-icon  "
                      />{" "}
                      Improved user experience leading to higher customer
                      satisfaction.
                    </span>
                    <span class="elementoricon"></span>
                  </li>
                  <li
                    class="elementoritem"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                  >
                    <span class="elementortext">
                      <FontAwesomeIcon
                        icon={faServicestack}
                        aria-hidden="true"
                        className="service-icon"
                      />{" "}
                      Seamless integration with social media platforms for
                      increased engagement.
                    </span>
                    <span class="elementoricon"></span>
                  </li>
                  <li
                    class="elementoritem"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                  >
                    <span class="elementortext">
                      <FontAwesomeIcon
                        icon={faServicestack}
                        aria-hidden="true"
                        className="service-icon"
                      />{" "}
                      Seamless integration with social media platforms for
                      increased engagement.
                    </span>
                    <span class="elementoricon"></span>
                  </li>
                </ul>
              </div>
            </div>
            </div>
            </div>
            <div className="col-lg-6  vice ml-5 ">
            <div
              class="ele1 ele-service"
              data-aos=""
              data-aos-offset="200"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing=""
              data-aos-mirror="true"
              data-aos-once="false"
            >
              {" "}
              <a class="servicelist">
                <div className="servicelist-head">
                  <h1><span className="our"> Our</span>  
                  <span className="ser">  Service</span> </h1>
                  <ul className="servicelist-ul">
                    <li className="servicelist-li">
                      <span class="elementoricon">
                        <FontAwesomeIcon
                          icon={faServicestack}
                          aria-hidden="true"
                        />{" "}
                      </span>
                     <span className="dev" >WEB DEVELOPMENT AND DESIGN</span>
                    </li>
                    <li className="servicelist-li">
                      {" "}
                      <span class="elementoricon">
                        <FontAwesomeIcon
                          icon={faServicestack}
                          aria-hidden="true"
                        />{" "}
                      </span>
                      <span className="dev" > E-COMMERCE SOLUTIONS</span>
                    </li>
                    <li className="servicelist-li">
                      {" "}
                      <span class="elementoricon">
                        <FontAwesomeIcon
                          icon={faServicestack}
                          aria-hidden="true"
                        />{" "}
                      </span>
                      <span className="dev" >  DIGITAL MARKETING</span>
                    </li>
                    <li className="servicelist-li">
                      {" "}
                      <span class="elementoricon">
                        <FontAwesomeIcon
                          icon={faServicestack}
                          aria-hidden="true"
                        />{" "}
                      </span>
                      <span className="dev" > MOBILE APP DEVELOPMENT</span>
                    </li>
                    <li className="servicelist-li">
                      {" "}
                      <span class="elementoricon">
                        <FontAwesomeIcon
                          icon={faServicestack}
                          aria-hidden="true"
                        />{" "}
                      </span>
                      <span className="dev" >ANALYTICS AND OPTIMIZATION</span>
                    </li>
                    <li className="servicelist-li">
                      {" "}
                      <span class="elementoricon">
                        <FontAwesomeIcon
                          icon={faServicestack}
                          aria-hidden="true"
                        />{" "}
                      </span>
                      <span className="dev" > GRAPHICS & UI UX DESIGN </span>
                    </li>
                  </ul>
                </div>
              </a>
            </div>
          </div>
          
          </div>
          </section> */}

          {/* <section className="presentation-box">
  <h4 className="service-o"><span class="ser">OUR</span> <span className="our">SERVICE</span> </h4>
  <ul>
  
 
                                
    <li>WEB DEVELOPMENT AND DESIGN</li> 
    <li>E-COMMERCE SOLUTION</li>
    <li> DIGITAL MARKETING</li>
    <li>MOBILE APP DEVELOPMENT</li>
    <li>ANALYTICS AND OPTIMIZATION</li>
    <li>GRAPHICS & UI UX DESIGN</li>
  </ul>
</section> */}

          {/* <div class="elementor-element ">
          <div class="elementorcontainer">
            <p>
              But our job doesn’t end with the launch of your website. We
              provide ongoing maintenance and support services to ensure your
              site remains updated, secure, and performing at its best. Be it
              integrating new features, updating content, or troubleshooting,
              we’ve got you covered.
            </p>
            <p>
              Let us create a website that not only tells your brand story, but
              also drives growth and elevates your online presence. Embrace the
              power of an outstanding web presence with our Web Design
              Service.&nbsp;
            </p>{" "}
          </div>
        </div> */}
        </section>
        <section
          className="cardservice-image"
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
        >
          <div className="container">
            <div class="cardsservice">
              <ul class="cards1">
                <li class="cards__item1">
                  <div class="cardservice">
                    <div class="card__content">
                      <div class="card__title">
                        <span className="our">   WEB DEVELOPMENT & DESIGN </span>
                      </div>
                      <p class="card__text  ">
                       


                        Your website is often the first point of contact for
                        potential customers. Our team of experienced designers
                        and developers create stunning, user-friendly websites
                        that captivate audiences and drive engagement.{" "}
                      </p>
                    </div>
                  </div>
                </li>
                <li class="cards__item1">
                  <div class="cardservice">
                    <div class="card__content">
                      <div class="card__title">
                        <span className="our">E-COMMERCE SOLUTIONS</span>
                      </div>
                      <p class="card__text  ">
                        In an age where online shopping is ubiquitous, having an
                        effective e-commerce platform is crucial. We specialize
                        in developing robust e-commerce solutions that
                        streamline the buying process and maximize conversions.
                      </p>
                    </div>
                  </div>
                </li>

                <li class="cards__item1">
                  <div class="cardservice">
                    <div class="card__content">
                      <div class="card__title  ">
                        <span className="our ours"> Digital Marketing
                        
                        </span>
                      </div>
                      <p class="card__text  ">
                      Standing out in a crowded digital landscape requires
                        strategic marketing efforts. From search engine
                        optimization (SEO) and pay-per-click (PPC) advertising
                        to social media marketing and content creation, we
                        leverage the latest tools and techniques to elevate your
                        brand and expand your reach.

                      </p>
                    </div>
                  </div>
                </li>
                <li class="cards__item1">
                  <div class="cardservice">
                    <div class="card__content">
                      <div class="card__title">
                        <span className="our">MOBILE APP DEVELOPMENT</span>
                      </div>
                      <p class="card__text  ">
                        With the majority of internet users accessing content
                        via mobile devices, having a mobile app can
                        significantly enhance user experience and engagement.
                        Our team develops custom mobile applications that are
                        intuitive, responsive, and aligned with your business
                        objectives.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="cards__item1">
                  <div class="cardservice">
                    <div class="card__content">
                      <div class="card__title">
                        <span className="our">ANALYTICS & OPTIMIZATION</span>
                      </div>
                      <p class="card__text ">
                        Analytics and Optimization: Data-driven insights are
                        invaluable for making informed business decisions. We
                        employ advanced analytics tools to track key metrics,
                        analyze performance, and optimize your digital assets
                        for maximum impact.
                      </p>
                    </div>
                  </div>
                </li>
                <li class="cards__item1">
                  <div class="cardservice">
                    <div class="card__content">
                      <div class="card__title">
                        <span className="our">GRAPHICS & UI UX DESIGN </span>
                      </div>
                      <p class="card__text ">
                        Our Graphics & UI/UX Design service combines creative
                        prowess with user-centric design principles to craft
                        visually compelling graphics and intuitive interfaces.
                        From captivating logos and branding materials to
                        seamless user experiences across websites and mobile
                        apps, we blend aesthetics with functionality to elevate
                        your brand and engage your audience effectively. With
                        meticulous attention to detail and a collaborative
                        approach, we tailor our designs to reflect your unique
                        identity and achieve your business objectives in the
                        digital landscape
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>

      <>
     








        {/* <section className="lasts">
  <div className="box">
      <h1>Heading Content</h1>
      <p>Paragraph content goes here. This is an example of a paragraph inside the box.</p>
      <div className="icon-circle">
        <i className="fa fa-star"></i> 
      </div>
    </div>
    </section> */}

        {/* <section class="iq-features">
          <div class="container">
            
            <div class="row ">
              <div class="col-lg-3 col-md-12">Our Technology</div>
              <div class="col-lg-6 col-md-12">
                <div class="holderCircle">
                  <div class="round"></div>
                  <div class="dotCircle">
                    <span class="itemDot active itemDot1" data-tab="1">
                      <i class="fa fa-clock-o"></i>
                      <span class="forActive"></span>
                    </span>
                    <span class="itemDot itemDot2" data-tab="2">
                      <i class="fa fa-comments"></i>
                      <span class="forActive"></span>
                    </span>
                    <span class="itemDot itemDot3" data-tab="3">
                      <i class="fa fa-user"></i>
                      <span class="forActive"></span>
                    </span>
                    <span class="itemDot itemDot4" data-tab="4">
                      <i class="fa fa-tags"></i>
                      <span class="forActive"></span>
                    </span>
                    <span class="itemDot itemDot5" data-tab="5">
                      <i class="fa fa-upload"></i>
                      <span class="forActive"></span>
                    </span>
                    <span class="itemDot itemDot6" data-tab="6">
                      <i class="fa fa-briefcase"></i>
                      <span class="forActive"></span>
                    </span>
                  </div>
                  <div class="contentCircle">
                    <div class="CirItem title-box active CirItem1">
                      <h2 class="title">
                        <span>Automate</span>
                      </h2>
                      <p>
                        tfhdfghfghfghfgjtyjtyhfg ftgfthtyjyutg rthtryjdrhrt
                        tyjdrytyjuty tjtyjtyjtdy
                      </p>
                      <i class="fa fa-clock-o"></i>
                    </div>
                    <div class="CirItem title-box CirItem2">
                      <h2 class="title">
                        <span>Chat </span>
                      </h2>
                      <p>
                        tfhdfghfghfghfgjtyjtyhfg ftgfthtyjyutg rthtryjdrhrt
                        tyjdrytyjuty tjtyjtyjtdy
                      </p>
                      <i class="fa fa-comments"></i>
                    </div>
                    <div class="CirItem title-box CirItem3">
                      <h2 class="title">
                        <span>Responses</span>
                      </h2>
                      <p>
                        tfhdfghfghfghfgjtyjtyhfg ftgfthtyjyutg rthtryjdrhrt
                        tyjdrytyjuty tjtyjtyjtdy
                      </p>
                      <i class="fa fa-user"></i>
                    </div>
                    <div class="CirItem title-box CirItem4">
                      <h2 class="title">
                        <span>Tags</span>
                      </h2>
                      <p>
                        tfhdfghfghfghfgjtyjtyhfg ftgfthtyjyutg rthtryjdrhrt
                        tyjdrytyjuty tjtyjtyjtdy
                      </p>
                      <i class="fa fa-tags"></i>
                    </div>
                    <div class="CirItem title-box CirItem5">
                      <h2 class="title">
                        <span>Sharing</span>
                      </h2>
                      <p>
                        tfhdfghfghfghfgjtyjtyhfg ftgfthtyjyutg rthtryjdrhrt
                        tyjdrytyjuty tjtyjtyjtdy
                      </p>
                      <i class="fa fa-upload"></i>
                    </div>
                    <div class="CirItem title-box CirItem6">
                      <h2 class="title">
                        <span>Support</span>
                      </h2>
                      <p>
                        tfhdfghfghfghfgjtyjtyhfg ftgfthtyjyutg rthtryjdrhrt
                        tyjdrytyjuty tjtyjtyjtdy
                      </p>
                      <i class="fa fa-briefcase"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-12"></div>
            </div>
          </div>
        </section> */}
<section className="shoot">
  <h1 className=''>Our Mobile App Development Services Include</h1>
  <h1></h1>
    <div className="container-fluid">
  <div className="row marginRow">
    <div className="col-md-4">
      <div className="box-shoot">
        <div className="eclipse">
          <i className="fa fa-leaf" />
        </div>
        <span className=''>Flutter App Development</span>
        <div className="clear" />
        <p>
        We are a top mobile app development company in this domain. We produce high-class iOS, Android, and Windows Mobile apps with the help of skilled technology partners.
        </p>
      </div>
    </div>
  
    <div className="col-md-4">
    <div className=''>
      <div className="box-shoot boxi">
        <div className="eclipse">
          <i className="fa fa-pencil" />
        </div>
        <span>Customization</span>
        <div className="clear " />
        <p>
        Infoane develops tailor-made applications from scratch based on client-defined specifications and samples. Infoane unleashes clients' creativity with the help of talented and experienced iOS, Android, and Windows Phone app developers.
        </p>
      </div>
      </div>
    </div>
    <div className="col-md-4">
      <div className="box-shoot ass">
        <div className="eclipse">
          <i className="fa fa-bullhorn" />
        </div>
        <span className=''>Qulaity  Mobile Apps</span>
        <div className="clear " />
        <p>
        We do not send out any project until we are 100% satisfied with the result created for our clients, regardless of how small or big it is at the final stage of the development process.
        </p>
      </div>
    </div>
  </div>
</div>
</section>





        
      </>
    </>
  );
};

export default View;