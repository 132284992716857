import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Handleslide.css';

import icon1 from "../asserts/firstpage/As img.jpg";
import icon2 from "../asserts/navbar/PGS.jpg";
import icon3 from "../asserts/firstpage/bharatgas.jpg";
import icon4 from "../asserts/navbar/PGS.jpg";

const Handleslide = () => {
    const reviewWrapRef = useRef(null);
    const leftArrowRef = useRef(null);
    const rightArrowRef = useRef(null);
    const imgDivRef = useRef(null);
    const personNameRef = useRef(null);
    const professionRef = useRef(null);
    const descriptionRef = useRef(null);
    const surpriseMeBtnRef = useRef(null);
    const chickenRef = useRef(null);

    const [isChickenVisible, setIsChickenVisible] = useState(false);
    const [currentPerson, setCurrentPerson] = useState(0);
    const [autoSlideInterval, setAutoSlideInterval] = useState(null);

    const people = [
        {
            photo: `url(${icon1})`,
            name: "Pro Global Solution",
            profession: "PGS Expensive App",
            description: "Introducing the PGS Expensive App, the ultimate tool for managing and optimizing your expenses with precision and ease. Designed for individuals and businesses alike, our app offers a comprehensive suite of features to help you take control of your finances and make smarter spending decisions."
        },
        {
            photo: `url(${icon2})`,
            name: "Pro Global Solution",
            profession: "Arasan Sales App",
            description: "Introducing the PGS Expensive App, the ultimate tool for managing and optimizing your expenses with precision and ease. Designed for individuals and businesses alike, our app offers a comprehensive suite of features to help you take control of your finances and make smarter spending decisions."
        },
        {
            photo: `url(${icon3})`,
            name: "Pro Global Solution",
            profession: "Balaji Gas App",
            description: "At Balajii Gas, we are dedicated to providing safe, reliable, and efficient LPG solutions for both residential and commercial customers. With a commitment to quality and customer satisfaction, we ensure that your energy needs are met with the highest standards of service and safety."
        },
        {
            photo: `url(${icon4})`,
            name: "Pro Global Solution",
            profession: "PGS Sales Automation",
            description: "In the fast-paced world of sales, efficiency and accuracy are paramount. The PGS Sales Automation app is designed to streamline your sales operations, empowering your team to close deals faster and more effectively. "
        }
    ];

    useEffect(() => {
        // Start automatic sliding when component mounts
        const intervalId = setInterval(() => {
            setNextCardLeft();
        }, 5000); // Change interval duration as needed (e.g., 5000 milliseconds = 5 seconds)

        setAutoSlideInterval(intervalId);

        return () => {
            // Clear interval when component unmounts
            clearInterval(autoSlideInterval);
        };
    }, []);

    const slide = (whichSide, personNumber) => {
        const reviewWrap = reviewWrapRef.current;
        const description = descriptionRef.current;
        const imgDiv = imgDivRef.current;
        const personName = personNameRef.current;
        const profession = professionRef.current;
        const chicken = chickenRef.current;

        if (!reviewWrap || !description || !imgDiv || !personName || !profession) return;

        const reviewWrapWidth = reviewWrap.offsetWidth + "px";
        const descriptionHeight = description.offsetHeight + "px";
        const side1symbol = whichSide === "left" ? "" : "-";
        const side2symbol = whichSide === "left" ? "-" : "";

        const tl = gsap.timeline();

        if (isChickenVisible) {
            tl.to(chicken, {
                duration: 0.4,
                opacity: 0
            });
        }

        tl.to(reviewWrap, {
            duration: 0.4,
            opacity: 0,
            translateX: `${side1symbol + reviewWrapWidth}`
        });

        tl.to(reviewWrap, {
            duration: 0,
            translateX: `${side2symbol + reviewWrapWidth}`
        });

        setTimeout(() => {
            imgDiv.style.backgroundImage = people[personNumber].photo;
        }, 400);
        setTimeout(() => {
            description.style.height = descriptionHeight;
        }, 400);
        setTimeout(() => {
            personName.innerText = people[personNumber].name;
        }, 400);
        setTimeout(() => {
            profession.innerText = people[personNumber].profession;
        }, 400);
        setTimeout(() => {
            description.innerText = people[personNumber].description;
        }, 400);

        tl.to(reviewWrap, {
            duration: 0.4,
            opacity: 1,
            translateX: 0
        });

        if (isChickenVisible) {
            tl.to(chicken, {
                duration: 0.4,
                opacity: 1
            });
        }
    };

    const setNextCardLeft = () => {
        setCurrentPerson((prevPerson) => {
            const newPerson = prevPerson === 3 ? 0 : prevPerson + 1;
            slide("left", newPerson);
            return newPerson;
        });
    };

    const setNextCardRight = () => {
        setCurrentPerson((prevPerson) => {
            const newPerson = prevPerson === 0 ? 3 : prevPerson - 1;
            slide("right", newPerson);
            return newPerson;
        });
    };

    return (
        <section className='appp-sec'>
            <div className="contented-wrapper">
                <h1 className='serv'>Our Applications</h1>
                <div className="blue-line" />
                <div className="wrapper-for-arrows">
                    <div style={{ opacity: 0 }} ref={chickenRef} className="chicken" />
                    <div id="reviewWrap" ref={reviewWrapRef} className="review-wrap">
                        <div id="imgDiv" ref={imgDivRef} className="img-div"></div>
                        <div id="personName" ref={personNameRef} />
                        <div id="profession" ref={professionRef} />
                        <div id="description" ref={descriptionRef}></div>
                    </div>
                    <div ref={surpriseMeBtnRef} className="surprise-me-btn">
                        Download
                    </div>
                    <div className="left-arrow-wrap arrow-wrap">
                        <div className="arrow" id="leftArrow" ref={leftArrowRef} />
                    </div>
                    <div className="right-arrow-wrap arrow-wrap">
                        <div className="arrow" id="rightArrow" ref={rightArrowRef} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Handleslide;
