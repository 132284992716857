import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faCogs, faTools, faBullseye, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import "../float/Float.css";  // Make sure the path to your CSS file is correct

const Float = () => {
  const location = useLocation();

  const IconWithLabel = ({ icon, label }) => (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
      <FontAwesomeIcon icon={icon} style={{ color: '#0b3b8e', marginRight: '8px' }} />
      <span>{label}</span>
    </div>
  );

  const isActive = (path) => location.pathname === path ? 'active' : '';

  useEffect(() => {
    const handleClick = (event) => {
      const togglers = document.querySelector(".togglers");
      const nav = document.querySelector(".nav");
      const buttonClicked = nav.contains(event.target);
      
      if (
        event.target.className === "togglers" ||
        event.target.className === "toggles"
      ) {
        document.body.classList.toggle("show-nav");
      } else if (
        event.target.className === "overlay" ||
        event.target.tagName === "A" ||
        buttonClicked
      ) {
        document.body.classList.remove("show-nav");
      }

      // Change Toggler Icon
      if (document.body.classList.contains("show-nav")) {
        togglers.innerHTML = "&laquo;";
      } else {
        togglers.innerHTML = "&raquo;";
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <>
      <div className="overlays" />
      <nav className="nav navbars">
        <div className="toggles">
          <span className="togglers">»</span>
        </div>
        <div className="logo">
          {/* <a href="#"> <img src={logo} alt="Logo Image" className="blackspic" /> </a>  */}
        </div>
        <ul className="navi">
          <li className={isActive("/")}>
            <Link to="/"><IconWithLabel icon={faCogs} label="We Do" /></Link>
          </li>
          <li className={isActive("/Service")}>
            <Link to="/Service"><IconWithLabel icon={faTools} label="Service" /></Link>
          </li>
          <li className={isActive("/Strategy")}>
            <Link to="/Strategy"><IconWithLabel icon={faBullseye} label="Strategy" /></Link>
          </li>
          <li className={isActive("/Client")}>
            <Link to="/Client"><IconWithLabel icon={faUser} label="Client" /></Link>
          </li>
          <li className={isActive("/Firstpage")}>
            <Link to="/Firstpage"><IconWithLabel icon={faBriefcase} label="Work" /></Link>
          </li>
          <li className={isActive("/Secondpage")}>
            <Link to="/Secondpage"><IconWithLabel icon={faEnvelope} label="Contact" /></Link>
          </li>
        </ul>
      </nav>
      <main></main>
    </>
  );
};

export default Float;
